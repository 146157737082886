/*jslint white: true, nomen: true */
/*global angular */
(function() {
  'use strict';

  angular.module('app.whereWeBuild')
    .controller('whereWeBuildCtrl', ['$scope', '$rootScope', '$timeout', 'uiGmapPromises', 'DataService', 'googleMapsStylesService', 'DeviceService', 'FormService', 'TrackingService',
      function($scope, $rootScope, $timeout, uiGmapPromises, DataService, googleMapsStylesService, DeviceService, FormService, TrackingService) {

      $scope.availableAcreages = [];

      var offsetCenter = function(GoogleMaps, theMap, latlng, offsetx, offsety) {
        // return;
          // latlng is the apparent centre-point
          // offsetx is the distance you want that point to move to the right, in pixels
          // offsety is the distance you want that point to move upwards, in pixels
          // offset can be negative
          // offsetx and offsety are both optional

          var scale = Math.pow(2, theMap.getZoom());

          var worldCoordinateCenter = theMap.getProjection().fromLatLngToPoint(latlng);
          var pixelOffset = new GoogleMaps.Point((offsetx/scale) || 0,(offsety/scale) ||0);

          var worldCoordinateNewCenter = new GoogleMaps.Point(
            worldCoordinateCenter.x - pixelOffset.x,
            worldCoordinateCenter.y + pixelOffset.y
          );

          var newCenter = theMap.getProjection().fromPointToLatLng(worldCoordinateNewCenter);

          theMap.setCenter(newCenter);

          theMap.setZoom(theMap.getZoom() - 1);

      };

      var loadAvailableAcreages = function() {
        return DataService('/api/available-acreage', 'availableAcreages')
          .promise
          .then(function(availableAcreages) {
            angular.forEach(availableAcreages, function(availableAcreage) {
              var marker = {
                longitude: availableAcreage.longitude,
                latitude: availableAcreage.latitude,
                idkey: availableAcreage.id,
                icon: '/images/site/'+availableAcreage.availability+'-marker.png',
                templateUrl: 'availableAcreageWindow.html',
                availableAcreage: availableAcreage
              };
              $scope.availableAcreages.push(marker);
            });

            return $scope.availableAcreages;
          });
      };

      var defaultCenter = {
        latitude: 45.638023,
        longitude: -122.663495
      };

      $scope.map = {
        control: {},
        markers: [],
        zoom: 10,
        center: {
          latitude: 45.679395,
          longitude: -122.597837
        },
        options: {
          streetViewControl: false,
          draggable: true,
          scrollwheel: false,
          mapTypeControl: false,
          panControl: true,
          zoomControl: true,
          styles: googleMapsStylesService.unsaturatedBrown
        },
        mapEvents: {
          click: function(maps, eventName, args) {
            hideWindows();
          }
        },
        window: {
          control: {},
          marker: {},
          show: false,
          closeClick: function() {
            this.show = false;
          },
          options: {
            boxClass: 'popup-wrapper',
            maxWidth: 210,
            //width: 550
          }
        }
      };

      var fitBoundsWithCurrentMarkers = function(theMap, GoogleMaps) {
        GoogleMaps.event.trigger(theMap, 'resize');

        var newBounds = new GoogleMaps.LatLngBounds();

        angular.forEach($scope.map.markers, function(marker) {
          newBounds.extend(new GoogleMaps.LatLng(
            (0.005 + (1 * marker.latitude)),
            (0.005 + (1 * marker.longitude))
          ));
        });

        theMap.fitBounds(newBounds);
        offsetCenter(GoogleMaps, theMap, theMap.getCenter(), 150, 75);
      };

      var hideWindows = function() {
        // hide any windows
        $scope.map.window.show = false;
        $scope.map.window.marker = null;
        $scope.map.window.model = null;

        if (!$scope.$$phase) {
          $scope.$apply();
        }
      };

      loadAvailableAcreages()
          .then(function(available_acreage_markers) {

            $scope.loading = false;
            uiGmapPromises.GoogleMapsApiReady()
              .then(function init(GoogleMaps) {

                var zoom = true;

                var zoomMax = 15;
                var zoomMin = 2;

                if(DeviceService.check() == 'phone'){
                  //zoomMax = 15;
                  // zoomMin = 9.5;
                }

                $scope.map.mapEvents = {
                  click: function(maps, eventName, args) {
                    hideWindows();
                  }
                };

                $scope.map.markersEvents = {
                  click: function(marker, eventName, model, args) {

                    $scope.map.window.options.pixelOffset = new GoogleMaps.Size(9, -120);

                    var markerCenter = {
                      latitude: marker.getPosition().lat(),
                      longitude: marker.getPosition().lng()
                    };
                    $scope.map.window.coords = markerCenter;

                    hideWindows();

                    $scope.map.window.marker = marker;
                    $scope.map.window.model = model;
                    $scope.map.window.show = true;

                    uiGmapPromises.GoogleMapsIsAllReady()
                    .then(function(theMap) {
                    });
                  }
                };

                $scope.map.window = {
                  marker: {},
                  control: {},
                  model: null,
                  show: false,
                  closeClick: function() {
                    hideWindows();
                    $scope.map.window.show = false;
                    $scope.map.window.model = {};
                  },
                  options: {
                    boxClass: 'popup-wrapper',
                    maxWidth: 210,
                    //width: 555
                  }
                };

                $scope.map.markers = $scope.availableAcreages;

                    uiGmapPromises.GoogleMapsIsAllReady()
                      .then(function(theMap) {

                        $timeout(function() {
                          theMap.setOptions({
                            streetViewControl: false,
                            draggable: true,
                            scrollwheel: false,
                            mapTypeControl: false,
                            panControl: false,
                            panControlOptions: {
                              position: GoogleMaps.ControlPosition.RIGHT_CENTER
                            },
                            zoomControl: zoom,
                            zoomControlOptions: {
                              position: GoogleMaps.ControlPosition.RIGHT_CENTER
                            },
                            maxZoom: zoomMax,
                            minZoom: zoomMin,
                            styles: googleMapsStylesService.unsaturatedBrown,
                            closeBoxURL: 'https://www.google.com/intl/en_us/mapfiles/close.gif'
                          });

                          // re-size and re-center map
                          //$scope.map.window.options.pixelOffset = new GoogleMaps.Size(-6, -53);
                          fitBoundsWithCurrentMarkers(theMap, GoogleMaps);

                          // resize trigger
                          GoogleMaps.event.addDomListener(window, "resize", function() {
                            fitBoundsWithCurrentMarkers(theMap, GoogleMaps);
                          });
                        });

                      })
                    ;

              });
          });
    	



      /*====================================
      =            Declarations            =
      ====================================*/

      var self = this,
        prefillMode = false;

      self.formData = FormService.prefillForm(prefillMode, 'availableAcreage').formData;
      self.formResult = FormService.prefillForm(prefillMode, 'availableAcreage').formResult;

      if (typeof self.trackingName === 'undefined') {
        self.trackingName = 'availableAcreageForm';
      }

      self.checkFormValidation = function(form) {
        self.triedSubmit = true;
        if (! form.$valid) {
          return;
        }

        processAvailableAcreageForm();
      };

      function processAvailableAcreageForm() {
        self.formResult.showForm = false;
        self.formResult.showLoader = true;

        // mapping form fields to sync with zapier notification
        self.formData.phone = self.formData.phone_primary;
        self.formData.message = 'what are you interested in? ' + self.formData.interested_in + ' ' + self.formData.notes;

        FormService.submit('/forms/log-only', self.formData)
          .then(function(result) {
            self.formResult = result;
            if (result.status === 'success') {
              console.log('tracking: ', 'availableAcreageForm');
              TrackingService.logTrackingClick('availableAcreageForm', result);

              $rootScope.loadPseudoConfirmation();
            }
          });
      }

    }]);
}());