/*jslint white: true, nomen: true */
/*global angular */
(function() {
  'use strict';

  angular.module('app.microsite')
    .controller('MicrositeSitePlanCtrl', ['$scope', '$window', 'uiGmapPromises', '$uibModal', 'DeviceService',
      function($scope, $window, uiGmapPromises, $uibModal, DeviceService) {
        $scope.gm = null;

        $scope.communityPromise
          .then(function(community) {
            angular.forEach(community.lot_maps, function(lotMap, key) {
              angular.forEach(lotMap.lots, function(lot, key) {
                lot.pin = '/images/site/pins/' + lot.status + '-pin.png';
              });
            });

            assignLotMapTiles(community.lot_maps[0]);
            setLots(community.lot_maps[0].lots);
          })
        ;

        $scope.map = {
          center: {
            latitude: 0,
            longitude: 0
          },
          control: {},
          options: {
            zoomControl: true,
            zoomControlOptions: {},
            mapTypeControl: false,
            scaleControl: false,
            streetViewControl: false,
            rotateControl: false,
            fullscreenControl: false,
            maxZoom: 4,
            minZoom: 2
          },
          markers: [],
          markersEvents: {
            click: function(marker, eventName, model, args) {
              if (model.status !== 'reserved' && model.status !== 'sold') {
                var pixelOffsetX = -240,
                    pixelOffsetY = (model.status === 'quick-move-in') ? -254 : -180;
                if (DeviceService.check() === 'phone') {
                  pixelOffsetX = -135,
                  pixelOffsetY = (model.status === 'quick-move-in') ? -510 : -360;
                }
                if ($scope.gm) {
                  $scope.map.window.options.pixelOffset = new $scope.gm.Size(pixelOffsetX, pixelOffsetY);
                }

                var markerCenter = {
                  latitude: marker.getPosition().lat(),
                  longitude: marker.getPosition().lng()
                };
                $scope.map.window.coords = markerCenter;

                hideWindows();

                $scope.map.window.marker = marker;
                $scope.map.window.model = model;
                $scope.map.window.show = true;
              }
            }
          },
          zoom: 2,
          window: {
            marker: {},
            control: {},
            model: null,
            show: false,
            closeClick: function() {
              hideWindows();
            },
            options: {
              boxClass: 'popup-wrapper',
              maxWidth: 480
            }
          },
          infoWindow: false
        };

        var hideWindows = function() {
          // hide any windows
          $scope.map.window.show = false;
          $scope.map.window.marker = null;
          $scope.map.window.model = null;

          if (!$scope.$$phase) {
            $scope.$apply();
          }
        };

        var assignLotMapTiles = (lotMap) => {
          var TILE_URL = lotMap.tiles_path;
          var layerID = 'my_custom_layer';

          uiGmapPromises.GoogleMapsApiReady()
            .then((gm) => {
              $scope.gm = gm;
              // Create a new ImageMapType layer.
              var layer = new gm.ImageMapType({
                name: layerID,
                getTileUrl: function (coord, zoom) {
                  var url = TILE_URL
                    .replace('{x}', coord.x)
                    .replace('{y}', coord.y)
                    .replace('{z}', zoom);
                  return url;
                },
                tileSize: new gm.Size(256, 256),
                minZoom: 1,
                maxZoom: 20
              });

              uiGmapPromises.GoogleMapsIsAllReady()
                .then((map) => {
                  // Register the new layer, then activate it.
                  map.mapTypes.set(layerID, layer);
                  map.setMapTypeId(layerID);
                });
            });
        };

        var setLots = (lots) => {
          $scope.map.markers = lots;
        };

        uiGmapPromises.GoogleMapsApiReady()
          .then((gm) => {
            uiGmapPromises.GoogleMapsIsAllReady()
              .then((map) => {
/*
                angular.element($window).on('resize', () => {
                  gm.event.trigger(map, 'resize');
                });

                gm.event.addListener(map, 'resize', () => {
                  fitBounds();
                });
*/
                // Put some limitations on how far the map can be dragged
                var latLngBounds = new gm.LatLngBounds(
                    new gm.LatLng(-60, -22.5), // Southwest
                    new gm.LatLng(60, 22.5) // Northeast
                );

                gm.event.addListener(map, 'drag', () => {
                  if (latLngBounds.contains(map.getCenter())) return;
                  // Out of bounds - Move the map back within the bounds
                  const center = map.getCenter();
                  const maxX = latLngBounds.getNorthEast().lng();
                  const maxY = latLngBounds.getNorthEast().lat();
                  const minX = latLngBounds.getSouthWest().lng();
                  const minY = latLngBounds.getSouthWest().lat();
                  let x = center.lng();
                  let y = center.lat();

                  if (x < minX) {
                    x = minX;
                  }
                  if (x > maxX) {
                    x = maxX;
                  }
                  if (y < minY) {
                    y = minY;
                  }
                  if (y > maxY) {
                    y = maxY;
                  }

                  map.setCenter(new gm.LatLng(y, x));
                });

                angular.extend($scope.map, {
                  options: {
                    zoomControlOptions: {
                      position: gm.ControlPosition.TOP_LEFT
                    }
                  },
                  markersEvents: {
                    click: function(marker, eventName, model, args) {
                      if (model.status !== 'reserved' && model.status !== 'sold') {
                        var pixelOffsetX = -240,
                            pixelOffsetY = (model.status === 'quick-move-in') ? -254 : -180;
                        if (DeviceService.check() === 'phone') {
                          pixelOffsetX = -135,
                          pixelOffsetY = (model.status === 'quick-move-in') ? -510 : -360;
                        }
                        $scope.map.window.options.pixelOffset = new gm.Size(pixelOffsetX, pixelOffsetY);
                        var markerCenter = {
                          latitude: marker.getPosition().lat(),
                          longitude: marker.getPosition().lng()
                        };
                        $scope.map.window.coords = markerCenter;

                        hideWindows();

                        $scope.map.window.marker = marker;
                        $scope.map.window.model = model;
                        $scope.map.window.show = true;
                      }
                    }
                  },
                  window: {
                    marker: {},
                    control: {},
                    model: null,
                    show: false,
                    closeClick: function() {
                      hideWindows();
                    },
                    options: {
                      boxClass: 'popup-wrapper'
                    }
                  },
                  markers: []
                });
              });
          });

        var fitBounds = () => {
          uiGmapPromises.GoogleMapsApiReady()
            .then((gm) => {
              uiGmapPromises.GoogleMapsIsAllReady()
                .then((map) => {
                  var newBounds = new gm.LatLngBounds();
                  $scope.map.markers.forEach((marker) => {
                    newBounds.extend(marker.position);
                  });
                  map.fitBounds(newBounds);
                });
            });
        };

    }]);
}());
